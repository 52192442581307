<template>
  <section>
    <ev-loader
      v-if="loadingApplicants"
      title=""
      :message="$t(`${langPath}.loading`)"
      data-test="sent-proposals-loader"
    />
    <section v-else-if="hasApplicants">
      <sent-proposal-details
        v-for="applicant in applicantsWithSentProposal"
        :key="applicant.id"
        :applicant="applicant"
        data-test="sent-proposal-details"
      />
    </section>
    <article
      v-else
      class="sent-proposals-card__empty-state"
    >
      <ev-icon
        class="sent-proposals-card__empty-state-icon"
        name="trophy"
        color="blue"
        size="medium"
        data-test="empty-state-icon"
      />
      <p
        data-test="empty-state-title"
        class="sent-proposals-card__empty-state-title"
      >
        {{ $t(`${langPath}.emptyState.title`) }}
      </p>
      <p
        class="sent-proposals-card__empty-state-description"
      >
        {{ $t(`${langPath}.emptyState.paragraph`) }}
      </p>
    </article>
    <section class="sent-proposals-card__footer">
      <ev-button
        v-if="hasPagesToLoad()"
        data-test="see-more-button"
        :disabled="loadingMoreApplicants"
        variant="tertiary"
        color="blue"
        size="small"
        @click="handleSeeMore"
      >
        <ev-circle-loader
          v-if="loadingMoreApplicants"
          variant="tertiary"
          color="blue"
          data-test="see-more-loader"
        />
        <span v-else>{{ $t(`${langPath}.seeMore`) }}</span>
      </ev-button>
    </section>
  </section>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import { APPLICANTS } from '@/store/namespaces'
import EvButton from '@revelotech/everestV2/EvButton'
import EvCircleLoader from '@revelotech/everestV2/EvCircleLoader'
import EvIcon from '@revelotech/everestV2/EvIcon'
import EvLoader from '@revelotech/everestV2/EvLoader'
import SentProposalDetails from './SentProposalDetails'

const applicantsHelper = createNamespacedHelpers(APPLICANTS)

export default {
  name: 'SentProposalsCard',
  components: {
    EvButton,
    EvCircleLoader,
    EvIcon,
    EvLoader,
    SentProposalDetails
  },
  data () {
    return {
      langPath: __langpath
    }
  },
  computed: {
    ...applicantsHelper.mapState([
      'applicantsWithSentProposal',
      'applicantsWithSentProposalPage',
      'applicantsWithSentProposalTotalPages',
      'loadingApplicants',
      'loadingMoreApplicants'
    ]),
    hasApplicants () {
      return !this.loadingApplicants && this.applicantsWithSentProposal.length
    }
  },
  created () {
    this.getApplicantsWithSentProposals()
  },
  methods: {
    ...applicantsHelper.mapActions(['getApplicantsWithSentProposals']),
    handleSeeMore () {
      this.getApplicantsWithSentProposals(this.applicantsWithSentProposalPage + 1)
    },
    hasPagesToLoad () {
      const currentPage = this.applicantsWithSentProposalPage
      const totalPages = this.applicantsWithSentProposalTotalPages

      return currentPage < totalPages
    }
  }
}
</script>

<style lang="scss">
.sent-proposals-card {
  &__empty-state {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 350px;
    justify-content: center;
    text-align: center;

    &-icon.interviews-card__empty-state-icon {
      margin-bottom: $base*6;
    }

    &-title {
      font: var(--tx-subtitle-1);
      margin-top: $base*6;
    }

    &-description {
      font: var(--tx-body-small);
      margin-top: $base*2;
      max-width: 50%;
    }
  }

  &__footer {
    margin-top: $base*4;
  }
}
</style>

<template>
  <section class="recommendation-empty-state-draft">
    <ev-icon
      class="recommendation-empty-state-draft__icon"
      name="clipboard"
      color="blue"
      size="medium"
      data-test="empty-state-icon"
    />

    <p
      data-test="empty-state-subtitle"
      class="recommendation-empty-state-draft__subtitle"
    >
      {{ $t(`${langPath}.subtitle`) }}
    </p>

    <i18n
      :path="`${langPath}.description`"
      class="recommendation-empty-state-draft__description"
      data-test="empty-state-description"
      tag="p"
    >
      <template #emphasis>
        <strong>{{ $t(`${langPath}.adherentEmphasis`) }}</strong>
      </template>
    </i18n>

    <section class="recommendation-empty-state-draft__actions">
      <ev-router-link
        :route="{ name: 'PositionsEdit', params: { positionId: position.externalId } }"
        data-test="empty-state-button"
      >
        {{ $t(`${langPath}.button`) }}
      </ev-router-link>
    </section>
  </section>
</template>
<script>
import EvIcon from '@revelotech/everestV2/EvIcon'
import EvRouterLink from '@revelotech/everestV2/EvRouterLink'

export default {
  name: 'RecommendationsEmptyStateDraft',
  components: {
    EvIcon,
    EvRouterLink
  },
  props: {
    position: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      langPath: __langpath
    }
  }
}
</script>
<style lang="scss">
.recommendation-empty-state-draft {
  text-align: center;

  &__icon {
    margin-bottom: $base*6;
  }

  &__subtitle {
    @extend %subtitle1;

    margin-top: $base*6;
    padding: 0 $base*8;
  }

  &__description {
    @extend %body-text2;

    margin-top: $base*2;

    strong {
      color: $red-light;
      font-size: inherit;
    }
  }

  &__actions {
    display: inline-block;
    margin-bottom: $base * 6;
    margin-top: $base * 6;
  }
}
</style>

<template>
  <div class="new-home">
    <div class="new-home__wrapper">
      <banner-end-of-plan class="new-home__banner" data-test="new-home__banner" />
      <div class="new-home__main">
        <next-tasks data-test="next-tasks" />
        <positions data-test="positions" />
      </div>
      <div class="new-home__sidebar">
        <signal-hiring-card />
        <recommendations-card v-if="companyNotInTrial" data-test="recommendations-card" />
        <interviews-card data-test="interviews-card" />
        <internal-manager-card data-test="internal-manager-card" />
        <plan-info-card data-test="plan-info-card" />
      </div>
    </div>
    <transition name="fade">
      <router-view />
    </transition>
  </div>
</template>

<script>
import InternalManagerCard from './components/InternalManagerCard'
import InterviewsCard from './components/InterviewsCard'
import Positions from './components/Positions'
import NextTasks from './components/NextTasks'
import RecommendationsCard from './components/RecommendationsCard'
import PlanInfoCard from './components/PlanInfoCard'
import BannerEndOfPlan from './components/BannerEndOfPlan.vue'
import SignalHiringCard from './components/SignalHiringCard'

import { createNamespacedHelpers } from 'vuex'
import { CURRENT_USER } from '@/store/namespaces'

const currentUserHelper = createNamespacedHelpers(CURRENT_USER)

export default {
  name: 'NewHome',
  components: {
    InternalManagerCard,
    InterviewsCard,
    Positions,
    NextTasks,
    RecommendationsCard,
    PlanInfoCard,
    BannerEndOfPlan,
    SignalHiringCard
  },
  computed: {
    ...currentUserHelper.mapState(['currentUser']),
    companyNotInTrial () {
      return !this.currentUser?.company?.trial
    }
  }
}
</script>

<style lang="scss">
.new-home {
  background: $gray5;
  flex-wrap: wrap;
  min-height: 100vh;
  padding: $app-header-height 0;

  &__wrapper {
    display: grid;
    grid-column-gap: $base*6;
    grid-row-gap: 0;
    grid-template-areas: "banner banner banner" "main main side-column";
    grid-template-columns: 3fr 3fr 4fr;
    margin: 0 auto;
    max-width: 1200px;
    padding: $base*6 $base*6;
  }

  &__main {
    grid-area: main;
  }

  &__banner {
    grid-area: banner;
  }

  &__side-column {
    grid-area: side-column;
  }

  &__container {
    background: $white;
    border: 1px solid $gray5;
    box-shadow: $shadow-8;
    height: auto;
    margin-top: $base*6;
    padding: 10*$base;
    width: 100%;

    &-title {
      @extend %h6;

      margin-bottom: $base*10;
    }
  }

  @media (max-width: 1024px) {
    &__wrapper {
      grid-template-areas: "banner" "main" "side-column";
      grid-template-columns: 1fr;
      padding: $base*12 $base*6;
    }
  }
}
</style>

<template>
  <section>
    <ev-loader
      v-if="loadingApplicants"
      title=""
      :message="$t(`${langPath}.loading`)"
      data-test="conducted-interviews-loader"
    />

    <section v-else-if="hasApplicants">
      <conducted-interview-details
        v-for="applicant in applicantsWithConductedInterview"
        :key="applicant.id"
        :applicant="applicant"
        data-test="conducted-interview-details"
      />
    </section>

    <article
      v-else
      class="conducted-interviews-card__empty-state"
    >
      <ev-icon
        class="conducted-interviews-card__empty-state-icon"
        color="blue"
        data-test="empty-state-icon"
        name="calendar-alt"
        size="medium"
      />

      <p
        class="conducted-interviews-card__empty-state-title"
        data-test="empty-state-title"
      >
        {{ $t(`${langPath}.emptyState.title`) }}
      </p>

      <i18n
        :path="`${langPath}.emptyState.paragraph`"
        class="conducted-interviews-card__empty-state-description"
        tag="p"
      >
        <em>
          {{ $t(`${langPath}.emptyState.timing`) }}
        </em>
      </i18n>
    </article>

    <section class="conducted-interviews-card__footer">
      <ev-button
        v-if="hasPagesToLoad()"
        data-test="see-more-button"
        :disabled="loadingMoreApplicants"
        variant="tertiary"
        color="blue"
        size="small"
        @click="handleSeeMore"
      >
        <ev-circle-loader
          v-if="loadingMoreApplicants"
          data-test="see-more-loader"
          variant="tertiary"
        />

        <span v-else>{{ $t(`${langPath}.seeMore`) }}</span>
      </ev-button>
    </section>
  </section>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import { APPLICANTS } from '@/store/namespaces'
import EvButton from '@revelotech/everestV2/EvButton'
import EvCircleLoader from '@revelotech/everestV2/EvCircleLoader'
import EvIcon from '@revelotech/everestV2/EvIcon'
import EvLoader from '@revelotech/everestV2/EvLoader'
import ConductedInterviewDetails from './ConductedInterviewDetails'

const applicantsHelper = createNamespacedHelpers(APPLICANTS)

export default {
  name: 'ConductedInterviewsCard',
  components: {
    EvButton,
    EvCircleLoader,
    EvIcon,
    EvLoader,
    ConductedInterviewDetails
  },
  data () {
    return {
      langPath: __langpath
    }
  },
  computed: {
    ...applicantsHelper.mapState([
      'applicantsWithConductedInterview',
      'applicantsWithConductedInterviewPage',
      'applicantsWithConductedInterviewTotalPages',
      'loadingApplicants',
      'loadingMoreApplicants'
    ]),
    hasApplicants () {
      return !this.loadingApplicants && this.applicantsWithConductedInterview.length
    }
  },
  created () {
    this.getApplicantsWithConductedInterviews()
  },
  methods: {
    ...applicantsHelper.mapActions([
      'getApplicantsWithConductedInterviews'
    ]),
    handleSeeMore () {
      this.getApplicantsWithConductedInterviews(this.applicantsWithConductedInterviewPage + 1)
    },
    hasPagesToLoad () {
      const currentPage = this.applicantsWithConductedInterviewPage
      const totalPages = this.applicantsWithConductedInterviewTotalPages

      return currentPage < totalPages
    }
  }
}
</script>

<style lang="scss">
.conducted-interviews-card {
  &__empty-state {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 350px;
    justify-content: center;
    text-align: center;

    &-icon.interviews-card__empty-state-icon {
      margin-bottom: $base*6;
    }

    &-title {
      font: var(--tx-subtitle-1);
      margin-top: $base*6;
    }

    &-description {
      font: var(--tx-body-small);
      margin-top: $base*2;
      max-width: 50%;
    }
  }

  &__footer {
    margin-top: $base*4;
  }
}
</style>

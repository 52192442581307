<template>
  <div
    class="sent-proposal-details"
    data-ga-id="clicked_on_sent_proposal_open_applicant_modal"
    @click.self="openApplicantModal"
  >
    <span
      data-ga-id="clicked_on_sent_proposal_open_applicant_modal"
      data-test="applicant-avatar-link"
      @click="openApplicantModal"
    >
      <applicant-avatar
        :avatar-url="applicant.candidateAvatar"
        :name="applicant.candidateName"
        data-test="applicant-avatar"
      />
    </span>
    <div class="sent-proposal-details__applicant-details">
      <span
        class="sent-proposal-details__applicant-name"
        data-ga-id="clicked_on_sent_proposal_open_applicant_modal"
        data-test="applicant-name"
        @click="openApplicantModal"
      >
        {{ applicant.candidateName }}
      </span>
      <span
        class="sent-proposal-details__position-title"
        data-ga-id="clicked_on_sent_proposal_open_workflow"
        data-test="position-title"
        @click="openPositionWorkflow"
      >
        {{ applicant.positionTitle }}
      </span>
    </div>
    <div
      class="sent-proposal-details__timestamp"
      data-test="timestamp"
      @click="openApplicantModal"
    >
      {{ formattedTime }}
    </div>
    <div class="sent-proposal-details__send-message">
      <ev-button
        color="blue"
        variant="secondary"
        size="small"
        data-ga-id="clicked_on_sent_proposal_send_message"
        data-test="send-message-button"
        @click="handleSendMessage"
      >
        {{ $t(`${langPath}.sendMessage`) }}
      </ev-button>
    </div>
    <div>
      <app-dropdown-menu
        data-test="menu"
      >
        <template slot="title">
          <ev-button
            variant="tertiary"
            color="blue"
            size="small"
            class="sent-proposal-details__menu-button"
          >
            <ev-icon
              name="ellipsis-h"
              color="blue"
            />
          </ev-button>
        </template>
        <app-item-menu>
          <span
            data-ga-id="clicked_on_sent_proposal_reject_applicant"
            data-test="reject-applicant"
            @click="handleRejectApplicant"
          >
            <ev-icon
              name="times-circle"
              color="gray60"
              class="sent-proposal-details__menu-icon"
            />
            {{ $t(`${langPath}.rejectCandidate`) }}
          </span>
        </app-item-menu>
      </app-dropdown-menu>
    </div>
  </div>
</template>

<script>
import ApplicantAvatar from '@/components/Applicants/ApplicantAvatar'
import AppDropdownMenu from '@/components/App/AppDropdownMenu'
import AppItemMenu from '@/components/App/AppItemMenu'
import EvButton from '@revelotech/everestV2/EvButton'
import EvIcon from '@revelotech/everestV2/EvIcon'

export default {
  name: 'SentProposalDetails',
  components: {
    ApplicantAvatar,
    AppDropdownMenu,
    AppItemMenu,
    EvButton,
    EvIcon
  },
  props: {
    applicant: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      langPath: __langpath
    }
  },
  computed: {
    formattedTime () {
      const date = new Date(this.updatedAt)

      return ` ${this.$moment(date).fromNow()}`
    },
    updatedAt () {
      return this.applicant.updatedAt
    },
    applicantId () {
      return this.applicant.id
    },
    positionId () {
      return this.applicant.positionId
    }
  },
  methods: {
    openPositionWorkflow () {
      const path =
        `#/workflow/${this.positionId}`

      window.open(path, '_blank')
    },
    openApplicantModal () {
      const path =
        `#/workflow/${this.positionId}/applicant/${this.applicantId}/proposal`

      window.open(path, '_blank')
    },
    handleSendMessage () {
      const path = `/#/companies/employers/invites/${this.applicant.externalInviteId}`

      window.open(path, '_blank')
    },
    handleRejectApplicant () {
      this.$router.push({
        name: 'NewHomeApplicantReject',
        params: { applicantId: this.applicantId }
      })
    }
  }
}
</script>

<style lang="scss">
.sent-proposal-details {
  align-items: center;
  display: flex;
  justify-content: space-between;
  list-style: none;
  padding: $base*4;

  &:hover {
    background: var(--bg-gray5);
    cursor: pointer;
  }

  &__applicant-details {
    display: flex;
    flex-direction: column;
    margin-left: $base*2;
    min-width: 188px;
  }

  &__applicant-name {
    font: var(--tx-body);
    max-width: 180px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__position-title {
    color: var(--tx-gray60);
    font: var(--tx-caption);
    max-width: 180px;

    &:hover {
      text-decoration: underline var(--tx-gray60);
    }
  }

  &__timestamp {
    color: var(--tx-gray60);
    font: var(--tx-overline);
    margin: 0 $base*2;
    text-align: center;
    text-transform: uppercase;
    width: 80px;
  }

  &__menu-button {
    height: $base*9;

    &:focus {
      outline: none;
    }
  }

  &__menu-icon {
    margin-right: $base;
  }

  &__send-message {
    white-space: nowrap;
  }
}
</style>

<template>
  <section v-if="candidates.length" class="recommended-candidate">
    <app-arrow-button
      direction="left"
      :disabled="moveLeftDisable"
      data-test="button-left"
      @click="moveLeft"
    />
    <main class="recommended-candidate__main">
      <recommendations-card-candidate-details
        v-if="!moveRightDisable"
        :candidate="currentCandidate"
        data-test="details"
      />
      <recommendations-finished-candidates
        v-else
        :position="position"
      />
    </main>
    <app-arrow-button
      direction="right"
      :disabled="moveRightDisable"
      data-test="button-right"
      @click="moveRight"
    />
  </section>
</template>

<script>
import AppArrowButton from '@/components/App/AppArrowButton'
import RecommendationsCardCandidateDetails from './RecommendationsCardCandidateDetails'
import RecommendationsFinishedCandidates from './RecommendationsFinishedCandidates'

export default {
  name: 'RecommendationsCardCandidates',
  components: {
    AppArrowButton,
    RecommendationsCardCandidateDetails,
    RecommendationsFinishedCandidates
  },
  props: {
    candidates: { type: Array, required: true },
    position: { type: Object, required: true }
  },
  data () {
    return {
      candidateIndex: 0
    }
  },
  computed: {
    currentCandidate () {
      return this.candidates[this.candidateIndex]
    },

    moveLeftDisable () {
      return this.candidateIndex <= 0
    },

    moveRightDisable () {
      return this.candidateIndex >= this.candidates.length
    }
  },
  methods: {
    moveLeft () {
      this.candidateIndex--
    },

    moveRight () {
      this.candidateIndex++
    }
  }
}
</script>

<style lang="scss">
.recommended-candidate {
  align-items: center;
  display: flex;
  margin-bottom: $base*2;

  &__navigation-button {
    border-radius: 50%;
    margin: 0 $base*2;
    width: 20px;
  }

  &__main {
    flex-grow: 1;
    padding: 0 4*$base;
  }
}
</style>

<template>
  <section>
    <div class="plan-info-card__plan-details-box">
      <span class="plan-info-card__plan-details-title">
        {{ $t(`${langPath}.availableCredits`) }}:
      </span>
      <span
        class="plan-info-card__plan-details-info"
        data-test="plan-details-available-credits"
      >
        <strong>{{ plan.availableCredits }}</strong>
      </span>
    </div>
    <div class="plan-info-card__plan-details-box">
      <span class="plan-info-card__plan-details-title">
        {{ $t(`${langPath}.usedCredits`) }}:
      </span>
      <span
        class="plan-info-card__plan-details-info"
        data-test="plan-details-used-credits"
      >
        <strong>{{ plan.usedCredits }}</strong>
      </span>
    </div>
    <div class="plan-info-card__plan-details-box">
      <span class="plan-info-card__plan-details-title">
        {{ $t(`${langPath}.creditsValidUntil`) }}:
      </span>
      <span
        class="plan-info-card__plan-details-info"
        data-test="plan-details-credits-valid-until"
      >
        <strong>
          {{ $d(new Date(plan.endDate), 'dayMonthFullYear') }}
        </strong>
      </span>
    </div>
    <div
      v-if="plan.buyCreditsLink"
      class="plan-info-card__buy-credits-link"
    >
      <ev-link
        data-test="manage-plan"
        color="blue"
        variant="secondary"
        target="blank"
        :a-href="plan.buyCreditsLink"
      >
        {{ $t(`${langPath}.buyCredits`) }}
      </ev-link>
    </div>
  </section>
</template>

<script>
import EvLink from '@revelotech/everestV2/EvLink'

export default {
  name: 'PrePaidPlanInfo',
  components: {
    EvLink
  },
  props: {
    plan: {
      type: Object,
      default: undefined
    }
  },
  data () {
    return {
      langPath: __langpath
    }
  }
}
</script>

<style lang="scss">
  .plan-info-card {
    &__buy-credits-link {
      margin-top: $base * 6;
      width: 40%;
    }
  }
</style>

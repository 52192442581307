<template>
  <section
    class="recommended-candidate-card"
    data-test="recommendations-card-candidate-details"
    @click="openCandidateProfile"
  >
    <header class="recommended-candidate-card__header">
      <img
        class="recommended-candidate-card__avatar"
        :src="candidate.avatarThumbUrl"
      >
      <h4 class="recommended-candidate-card__name">
        {{ candidate.name }}
      </h4>
    </header>

    <dl>
      <dt class="recommended-candidate-card__title-info">
        {{ $t(`${langPath}.minimumSalary`) }}
      </dt>
      <dd data-test="minimum-salary" class="recommended-candidate-card__salary">
        {{ $n(candidate.minimumSalary, 'currency') }}
      </dd>

      <dt class="recommended-candidate-card__title-info">
        {{ $t(`${langPath}.currentJobTitle`) }}
      </dt>
      <dd
        class="recommended-candidate-card__job truncated-two-lines"
        data-test="current-job-title"
      >
        {{ currentJobTitle }}
      </dd>

      <dt class="recommended-candidate-card__title-info">
        {{ $t(`${langPath}.principalSkills`) }}
      </dt>
      <dd
        class="recommended-candidate-card__info truncated-two-lines"
        data-test="principal-skills"
      >
        {{ humanizedPrincipalSkills }}
      </dd>
    </dl>
  </section>
</template>

<script>
import getLastJob from '@/helpers/get-last-job.js'
import { toCamelCase } from '@/helpers/case-style'

export default {
  name: 'RecommendationsCardCandidateDetails',
  props: { candidate: { type: Object, required: true } },
  data () {
    return {
      langPath: __langpath
    }
  },
  computed: {
    currentJobTitle () {
      return getLastJob(this.candidate.jobs)?.title
    },

    humanizedPrincipalSkills () {
      if (!this.candidate.principalSkills?.length) {
        return this.$t(`${this.langPath}.emptyPrincipalSkills`)
      }

      const skills = this.candidate.principalSkills.map((skill) => {
        const humanizedExperienceTime = this.humanizeExperienceTime(skill.experienceTime)

        return `${skill.label} (${humanizedExperienceTime})`
      })

      return skills.join(this.$t(`${this.langPath}.principalSkillsSeparator`))
    }
  },
  methods: {
    humanizeExperienceTime (experienceTime) {
      if (!experienceTime) return this.$t(`${this.langPath}.emptyExperienceTime`)

      const camelizedExperienceTime = toCamelCase(experienceTime)

      return this.$t(
        `${this.langPath}.experienceTime.${camelizedExperienceTime}`
      )
    },
    openCandidateProfile () {
      const { href } = this.$router.resolve({
        name: 'CandidateProfile',
        params: { id: this.candidate.id },
        query: { source: 'homeRecommendation' }
      })

      window.open(href, '_blank')
    }
  }
}
</script>

<style lang="scss">
@import "@revelotech/everestV2/tx_overline.scss";

.recommended-candidate-card {
  background: rgba(255, 103, 123, 0.05);
  cursor: pointer;
  margin: 0 $base;
  padding: $base*6;

  &__avatar {
    background-color: $gray5;
    border-radius: 50%;
    height: $base*10;
    object-fit: cover;
    width: $base*10;
  }

  &__header {
    align-items: center;
    display: flex;
  }

  &__title-info {
    @extend %tx-overline-small;

    margin-top: $base*5;
  }

  &__info {
    font: var(--tx-body-small);
    margin-bottom: $base*5;
  }

  &__salary {
    font: var(--tx-body-small-he);
  }

  &__job {
    font: var(--tx-body-small);
    max-width: $base*70;
  }

  &__name {
    font: var(--tx-title-4);
    margin-left: $base*4;
    max-width: $base*45;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .truncated-two-lines {
    -webkit-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }
}
</style>

<template>
  <section>
    <card-skeleton
      v-if="isFetchingInternalManager"
      data-test="manager-skeleton"
    />
    <section
      v-if="hasManager"
      class="internal-manager-card"
      data-test="internal-manager-card"
    >
      <h1 class="internal-manager-card__title">
        {{ $t(`${langPath}.title`) }}
      </h1>
      <p class="internal-manager-card__subtitle">
        {{ $t(`${langPath}.subtitle`) }}
      </p>
      <p class="internal-manager-card__name" data-test="name">
        {{ internalManager.name }}
      </p>
      <a
        class="internal-manager-card__info"
        data-test="email"
        :href="mailToHRef"
      >
        {{ internalManager.email }}
      </a>
    </section>
  </section>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import { INTERNAL_MANAGER } from '@/store/namespaces'
import CardSkeleton from './skeletons/CardSkeleton'

const internalManagerHelper = createNamespacedHelpers(INTERNAL_MANAGER)

export default {
  name: 'InternalManagerCard',
  components: {
    CardSkeleton
  },
  data () {
    return {
      langPath: __langpath
    }
  },
  computed: {
    ...internalManagerHelper.mapState([
      'internalManager', 'isFetchingInternalManager'
    ]),
    hasManager () {
      return Object.keys(this.internalManager).length > 0
    },
    mailToHRef () {
      return `mailto:${this.internalManager.email}`
    }
  },
  created () {
    this.getInternalManager()
  },
  methods: {
    ...internalManagerHelper.mapActions(['getInternalManager'])
  }
}
</script>

<style lang="scss" scoped>
.internal-manager-card {
  background: $white;
  border: 1px solid $gray5;
  border-radius: var(--b-radius);
  box-shadow: $shadow-8;
  height: auto;
  margin-top: $base*6;
  padding: 6*$base;
  width: 100%;

  &__title {
    @extend %h6;

    @include margin(bottom, 2);
  }

  &__subtitle {
    font: var(--tx-body);

    @include margin(bottom, 4);
  }

  &__name {
    font: var(--tx-title-4);

    @include margin(bottom, 2);
  }

  &__info {
    @include margin(top, 2);

    color: var(--b-blue);
    font: var(--tx-body-small);
  }

  &__phone {
    display: flex;
  }

  &__contact {
    flex-grow: 1;

    &--whatsapp {
      color: var(--b-blue);
      flex-grow: 1;
      font: var(--tx-button);
    }
  }
}
</style>

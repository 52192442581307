<template>
  <section>
    <ev-loader
      v-if="loadingApplicants"
      title=""
      :message="$t(`${langPath}.loading`)"
      data-test="unscheduled-interviews-loader"
    />
    <section v-else-if="hasApplicants">
      <unscheduled-interview-details
        v-for="applicant in applicantsWithUnscheduledInterview"
        :key="applicant.id"
        :applicant="applicant"
        data-test="unscheduled-interview-details"
      />
    </section>
    <article
      v-else
      class="unscheduled-interviews-card__empty-state"
    >
      <ev-icon
        class="unscheduled-interviews-card__empty-state-icon"
        name="user-plus"
        color="blue"
        size="medium"
        data-test="empty-state-icon"
      />
      <p
        data-test="empty-state-title"
        class="unscheduled-interviews-card__empty-state-title"
      >
        {{ $t(`${langPath}.emptyState.title`) }}
      </p>
      <p
        class="unscheduled-interviews-card__empty-state-description"
      >
        {{ $t(`${langPath}.emptyState.paragraph`) }}
      </p>
      <section class="unscheduled-interviews-card__empty-state-actions">
        <ev-link
          a-href="/#/companies/employers/candidates"
          data-test="empty-state-button"
        >
          {{ $t(`${langPath}.emptyState.button`) }}
        </ev-link>
      </section>
    </article>
    <section class="unscheduled-interviews-card__footer">
      <ev-button
        v-if="hasPagesToLoad()"
        data-test="see-more-button"
        :disabled="loadingMoreApplicants"
        variant="tertiary"
        color="blue"
        size="small"
        @click="handleSeeMore"
      >
        <ev-circle-loader
          v-if="loadingMoreApplicants"
          variant="tertiary"
          data-test="see-more-loader"
        />
        <span v-else>{{ $t(`${langPath}.seeMore`) }}</span>
      </ev-button>
    </section>
  </section>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import { APPLICANTS } from '@/store/namespaces'
import EvButton from '@revelotech/everestV2/EvButton'
import EvCircleLoader from '@revelotech/everestV2/EvCircleLoader'
import EvIcon from '@revelotech/everestV2/EvIcon'
import EvLink from '@revelotech/everestV2/EvLink'
import EvLoader from '@revelotech/everestV2/EvLoader'
import UnscheduledInterviewDetails from './UnscheduledInterviewDetails'

const applicantsHelper = createNamespacedHelpers(APPLICANTS)

export default {
  name: 'UnscheduledInterviewsCard',
  components: {
    EvButton,
    EvCircleLoader,
    EvIcon,
    EvLink,
    EvLoader,
    UnscheduledInterviewDetails
  },
  data () {
    return {
      langPath: __langpath
    }
  },
  computed: {
    ...applicantsHelper.mapState([
      'applicantsWithUnscheduledInterview',
      'applicantsWithUnscheduledInterviewPage',
      'applicantsWithUnscheduledInterviewTotalPages',
      'loadingApplicants',
      'loadingMoreApplicants'
    ]),
    hasApplicants () {
      return !this.loadingApplicants && this.applicantsWithUnscheduledInterview.length
    }
  },
  created () {
    this.getApplicantsWithUnscheduledInterviews()
  },
  methods: {
    ...applicantsHelper.mapActions([
      'getApplicantsWithUnscheduledInterviews'
    ]),
    handleSeeMore () {
      this.getApplicantsWithUnscheduledInterviews(this.applicantsWithUnscheduledInterviewPage + 1)
    },
    hasPagesToLoad () {
      const currentPage = this.applicantsWithUnscheduledInterviewPage
      const totalPages = this.applicantsWithUnscheduledInterviewTotalPages

      return currentPage < totalPages
    }
  }
}
</script>

<style lang="scss">
.unscheduled-interviews-card {
  &__empty-state {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 350px;
    justify-content: center;
    text-align: center;

    &-icon.interviews-card__empty-state-icon {
      margin-bottom: $base*6;
    }

    &-title {
      font: var(--tx-subtitle-1);
      margin-top: $base*6;
    }

    &-description {
      font: var(--tx-body-small);
      margin-top: $base*2;
      max-width: 60%;
    }

    &-actions {
      display: inline-block;
      margin-top: $base * 6;
    }
  }

  &__footer {
    margin-top: $base*4;
  }
}
</style>

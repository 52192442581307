import { render, staticRenderFns } from "./NextTasks.vue?vue&type=template&id=2497ab4d&"
import script from "./NextTasks.vue?vue&type=script&lang=js&"
export * from "./NextTasks.vue?vue&type=script&lang=js&"
import style0 from "./NextTasks.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
<template>
  <section class="positions-listing-header">
    <div class="positions-listing-header__titles">
      <p
        class="positions-listing-header__title"
        data-test="my-positions-title"
      >
        {{ $t(`${langPath}.myPositions`) }}
      </p>
      <p
        class="positions-listing-header__title"
        data-test="total-applicants-title"
      >
        {{ $t(`${langPath}.applicantsTotal`) }}
      </p>
    </div>
  </section>
</template>

<script>
export default {
  name: 'PositionsListingHeader',
  data () {
    return {
      langPath: __langpath
    }
  }
}
</script>

<style lang="scss" scoped>
.positions-listing-header {
  @include breakpoint(md) {
    border-bottom: 1px solid $gray20;
    display: flex;
    flex-direction: row;
    padding: $base*6 $base*6;

    @include margin(top, 6);

    &__titles {
      display: flex;
      flex-grow: 2;
      justify-content: space-between;
    }
  }

  &__title {
    @extend %caption;
    @extend %bold;

    color: $gray100;
    margin: 0;
    padding: 0;
    text-transform: uppercase;
  }

  display: none;
}
</style>

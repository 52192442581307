<template>
  <div class="signal-hiring">
    <p
      class="signal-hiring__overline"
      data-test="signal-hiring-overline"
    >
      {{ $t(`${langPath}.news`) }}
    </p>
    <h1
      class="signal-hiring__header"
      data-test="signal-hiring-header"
    >
      {{ $t(`${langPath}.title`) }}
    </h1>
    <p
      class="signal-hiring__content"
      data-test="signal-hiring-content"
    >
      {{ $t(`${langPath}.content`) }}
    </p>
    <div
      class="signal-hiring__link"
      data-test="signal-hiring-link"
    >
      <ev-link
        data-test="signal-hiring-appcues-link"
        size="small"
        a-href="javascript:Appcues.show('cc1c0e67-e391-4b90-b3ff-33926c94f9ce')"
      >
        {{ $t(`${langPath}.link`) }}
      </ev-link>
    </div>
  </div>
</template>

<script>
import EvLink from '@revelotech/everestV2/EvLink'

export default {
  name: 'SignalHiringCard',
  components: {
    EvLink
  },
  data () {
    return {
      langPath: __langpath
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@revelotech/everestV2/tx_overline.scss";

.signal-hiring {
  background: var(--bg-neutral-blank);
  border: 1px solid var(--b-gray5);
  border-radius: var(--b-radius);
  box-shadow: var(--shadow-8);
  height: auto;
  margin-top: $base * 6;
  padding: 6 * $base;
  width: 100%;

  &__overline {
    @extend %tx-overline;

    background-color: var(--bg-red-light-02);
    border-radius: $base * 3 / 4;
    display: inline-block;
    margin-bottom: $base *3;
    padding: $base / 4 $base;
  }

  &__header {
    font: var(--tx-title-4);
    margin: $base * 3 0;
  }

  &__content {
    font: var(--tx-body-small);
    margin: $base * 3 0;
  }

  &__link {
    @include breakpoint(lg) {
      width: $base * 40;
    }

    margin-top: $base * 3 0;
    width: 100%;
  }
}
</style>

<template>
  <section class="banner-end-of-plan">
    <section v-if="isLoaded">
      <article
        v-if="showBanner"
        class="ev-callout ev-callout--red"
        data-test="callout-banner-end-of-plan"
      >
        <section class="ev-callout__content">
          <div class="ev-callout__content-left">
            <ev-icon
              name="exclamation-triangle"
              color="red"
              class="ev-callout__icon--left"
              data-test="left-icon"
            />

            <div class="ev-callout__content-info">
              <header
                class="ev-callout__heading"
              >
                <h1
                  class="ev-callout__overtitle-text"
                  data-test="overtitle"
                >
                  {{ title }}
                </h1>
              </header>

              <p
                class="ev-callout__description"
                data-test="description"
              >
                {{ $t(`${langPath}.description`) }}
              </p>
            </div>
          </div>

          <ev-button
            color="red"
            data-test="callout-banner-end-of-plan_button"
            size="small"
            @click="handleClick"
          >
            {{ $t(`${langPath}.button`) }}
          </ev-button>
        </section>
      </article>
    </section>
  </section>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import { PLANS } from '@/store/namespaces'
import EvButton from '@revelotech/everestV2/EvButton'
import EvIcon from '@revelotech/everestV2/EvIcon'
import companyPlansIds from '@/helpers/constants/company-plans'

const plansHelper = createNamespacedHelpers(PLANS)

export default {
  name: 'BannerEndOfPlan',
  components: {
    EvButton,
    EvIcon
  },
  data () {
    return {
      langPath: __langpath
    }
  },
  computed: {
    ...plansHelper.mapState([
      'currentPlan',
      'isLoadingCurrentPlan',
      'lastPlan',
      'isLoadingLastPlan'
    ]),
    isLoaded () {
      return !this.isLoadingCurrentPlan && !this.isLoadingLastPlan
    },
    showBanner () {
      const LIMIT = 15
      if (this.companyPlan) {
        if (this.isPrePaidPlanCreditsOver) {
          return true
        }
        if (this.isPlanActive && this.daysLeft <= LIMIT) {
          return true
        }
      }
      return false
    },
    availablePlanIds () {
      return companyPlansIds
    },
    endDate () {
      if (this.companyPlan.contractCompletedAt != null) {
        return this.companyPlan.contractCompletedAt
      }
      return this.companyPlan.endDate
    },
    companyPlan () {
      const plan = this.currentPlan
      if (plan && Object.keys(plan).length) {
        return plan
      }

      const lastPlan = this.lastPlan
      if (lastPlan && Object.keys(lastPlan).length) {
        return lastPlan
      }

      return null
    },
    isFreedomPlan () {
      return this.companyPlan.planId === this.availablePlanIds.freedomPlanId
    },
    isPrePaidPlan () {
      return this.companyPlan.planId === this.availablePlanIds.prePaidPlanId
    },
    isPrePaidPlanCreditsOver () {
      return this.isPrePaidPlan && this.companyPlan.contractCompletedAt != null
    },
    isPlanActive () {
      return (this.isFreedomPlan || this.isPrePaidPlan) &&
        this.companyPlan.contractCompletedAt == null &&
        this.companyPlan.contractCancelledAt == null &&
        this.$moment(this.companyPlan.startDate) < this.$moment() &&
        this.$moment(this.companyPlan.endDate) > this.$moment()
    },
    daysLeft () {
      const today = this.$moment()
      const date = this.$moment(this.endDate)

      return date.diff(today, 'days')
    },
    title () {
      const days = `${this.daysLeft} ${this.$tc('date.units.days', this.daysLeft)}`
      const date = this.$d(this.$moment(this.endDate), 'dayMonthFullYear')
      let titlePath = 'FreedomPlanTitle'
      if (this.isPrePaidPlan) {
        titlePath = 'PrePaidPlanTitle'
      }
      if (this.isPrePaidPlanCreditsOver) {
        titlePath = 'PrePaidPlanCreditsOverTitle'
      }
      return this.$t(`${this.langPath}.${titlePath}`, { days: days, date: date })
    }
  },
  async created () {
    this.getCurrentPlan()
    this.getLastPlan()
  },
  methods: {
    ...plansHelper.mapActions(['getCurrentPlan', 'getLastPlan']),
    handleClick () {
      const path =
        'https://api.whatsapp.com/send?phone=5511961637657'

      window.open(path, '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
$base: 4px;

.banner-end-of-plan {
  .ev-callout {
    --background-color: var(--bg-neutral-blank);

    align-items: center;
    background-color: var(--background-color);
    border-radius: var(--b-radius);
    box-shadow: $shadow-8;
    display: flex;
    justify-content: space-between;
    padding: $base * 6;

    &__overtitle {
      &-text {
        color: var(--tx-neutral-base);
        font: var(--tx-title-4);
      }
    }

    &__description {
      font: var(--tx-body);
    }

    &__icon {
      &--left {
        margin-right: $base * 4;
      }
    }

    &__content {
      align-items: center;
      display: flex;
      justify-content: space-between;
      width: 100%;

      &-left {
        align-items: center;
        display: flex;
      }
    }

    // Themes Configuration

    &--red {
      --background-color: var(--bg-red-light-03);
    }
  }
}
</style>

<template>
  <section class="new-home-position-card">
    <section
      class="new-home-position-card__container"
      @click="redirectToWorkflowBoard"
    >
      <h4
        class="new-home-position-card__title"
        data-test="title"
      >
        {{ position.title }}
      </h4>
      <section
        data-test="applicants-total"
        class="new-home-position-card__applicants-total"
      >
        {{ position.activeApplicantsCount }}
      </section>
    </section>
  </section>
</template>

<script>
export default {
  name: 'PositionCard',
  props: {
    position: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      langPath: __langpath
    }
  },
  methods: {
    redirectToWorkflowBoard () {
      if (!this.position.visible) return
      this.$router.push({
        name: 'WorkflowBoard',
        params: { positionId: this.position.id }
      })
    }
  }
}
</script>

<style lang="scss" >
.new-home-position-card {
  background-color: $white;
  font: var(--tx-body);
  padding: $base*6 $base*6;

  &__container {
    display: flex;
    flex-grow: 3;
    justify-content: space-between;
    width: 100%;
  }

  &__applicants-total {
    @extend %bold;

    color: $gray100;
    cursor: pointer;
    display: inline-block;
    padding: 0 $base*8;
  }

  &__title {
    cursor: pointer;
    display: inline-block;
  }

  &:hover {
    background: $gray5;
    cursor: pointer;

    .new-home-position-card__title {
      @extend %bold;

      color: $gray100;
    }
  }
}
</style>

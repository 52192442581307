<template>
  <section class="recommendation-finished-candidates">
    <ev-icon
      class="recommendation-finished-candidates__icon"
      name="clipboard-notes"
      color="blue"
      size="medium"
      data-test="finished-icon"
    />

    <p
      data-test="finished-subtitle"
      class="recommendation-finished-candidates__subtitle"
    >
      {{ $t(`${langPath}.subtitle`) }}
    </p>

    <p
      data-test="finished-description"
      class="recommendation-finished-candidates__description"
    >
      {{ $t(`${langPath}.description`) }}
    </p>

    <section class="recommendation-finished-candidates__actions">
      <ev-router-link
        :route="{ path: marketPlaceRoute }"
        data-test="finished-button"
      >
        {{ $t(`${langPath}.button`) }}
      </ev-router-link>
    </section>
  </section>
</template>

<script>
import EvIcon from '@revelotech/everestV2/EvIcon'
import EvRouterLink from '@revelotech/everestV2/EvRouterLink'
import positionsRepository from '@/repository/companies-app/positions'

export default {
  name: 'RecommendationsFinishedCandidates',

  components: {
    EvIcon,
    EvRouterLink
  },

  props: {
    position: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      langPath: __langpath,
      positions: []
    }
  },

  computed: {
    marketPlaceRoute () {
      if (this.positions.length) return `/companies${this.positions[0].searchPageHref}`

      return '/companies/employers/candidates'
    }
  },

  async created () {
    this.positions = await positionsRepository.getPositions([this.position.externalId])
  }
}
</script>

<style lang="scss">
.recommendation-finished-candidates {
  text-align: center;

  &__icon {
    margin-bottom: $base*6;
  }

  &__subtitle {
    @extend %subtitle1;

    margin-top: $base*6;
    padding: 0 32px;
  }

  &__description {
    @extend %body-text2;

    margin-top: $base*2;

    strong {
      color: $red-light;
      font-size: inherit;
    }
  }

  &__actions {
    display: inline-block;
    margin-bottom: $base * 6;
    margin-top: $base * 6;
  }
}
</style>

<template>
  <section class="next-tasks">
    <h1
      class="next-tasks__title"
      data-test="title"
    >
      {{ $t(`${langPath}.title`) }}
    </h1>
    <next-tasks-tabs data-test="next-tasks-tabs" />
    <ev-loader
      v-if="isActiveTab('')"
      title=""
      :message="$t(`${langPath}.loading`)"
      data-test="conducted-interviews-loader"
    />
    <unscheduled-interviews-card
      v-show="isActiveTab('unscheduledInterview')"
      data-test="unscheduled-interviews-card"
    />
    <conducted-interviews-card
      v-show="isActiveTab('conductedInterview')"
      data-test="conducted-interviews-card"
    />
    <sent-proposals-card
      v-show="isActiveTab('sentProposal')"
      data-test="sent-proposals-card"
    />
  </section>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import { APPLICANTS, NEXT_TASKS } from '@/store/namespaces'

import EvLoader from '@revelotech/everestV2/EvLoader'
import NextTasksTabs from './NextTasks/NextTasksTabs'
import UnscheduledInterviewsCard from './NextTasks/UnscheduledInterviewsCard'
import ConductedInterviewsCard from './NextTasks/ConductedInterviewsCard'
import SentProposalsCard from './NextTasks/SentProposalsCard'

const applicantsHelper = createNamespacedHelpers(APPLICANTS)
const nextTasksHelper = createNamespacedHelpers(NEXT_TASKS)

export default {
  name: 'NextTasks',
  components: {
    EvLoader,
    UnscheduledInterviewsCard,
    ConductedInterviewsCard,
    SentProposalsCard,
    NextTasksTabs
  },
  data () {
    return {
      langPath: __langpath
    }
  },
  computed: {
    ...applicantsHelper.mapState([
      'applicantsWithUnscheduledInterviewTotalEntries',
      'applicantsWithConductedInterviewTotalEntries',
      'applicantsWithSentProposalTotalEntries'
    ]),
    ...nextTasksHelper.mapState(['activeTab'])
  },
  async mounted () {
    await this.getApplicantsWithUnscheduledInterviews()
    await this.getApplicantsWithConductedInterviews()
    await this.getApplicantsWithSentProposals()

    const unscheduledInterviews = this.applicantsWithUnscheduledInterviewTotalEntries
    const conductedInterviews = this.applicantsWithConductedInterviewTotalEntries
    const sentProposals = this.applicantsWithSentProposalTotalEntries

    if (unscheduledInterviews === 0 && conductedInterviews > 0) {
      this.setActiveTab('conductedInterview')
    } else if (unscheduledInterviews === 0 && sentProposals > 0) {
      this.setActiveTab('sentProposal')
    } else {
      this.setActiveTab('unscheduledInterview')
    }
  },
  methods: {
    ...applicantsHelper.mapActions([
      'getApplicantsWithUnscheduledInterviews',
      'getApplicantsWithConductedInterviews',
      'getApplicantsWithSentProposals'
    ]),
    ...nextTasksHelper.mapActions(['setActiveTab']),
    isActiveTab (tabName) {
      return this.activeTab === tabName
    }
  }
}
</script>

<style lang="scss">
.next-tasks {
  background: $white;
  border: 1px solid $gray5;
  border-radius: var(--b-radius);
  box-shadow: $shadow-8;
  height: auto;
  margin-top: $base*6;
  padding: 6*$base;
  width: 100%;

  &__title {
    @extend %h6;

    margin-bottom: $base*6;
  }
}
</style>

<template>
  <section
    class="recommendation-empty-state-candidates"
    data-test="recommendations-empty-state-candidates"
  >
    <ev-icon
      class="recommendation-empty-state-candidates__icon"
      name="search-alt"
      color="blue"
      size="medium"
      data-test="empty-state-icon"
    />

    <p
      class="recommendation-empty-state-candidates__subtitle"
      data-test="empty-state-subtitle"
    >
      {{ $t(`${langPath}.subtitle`) }}
    </p>

    <p
      class="recommendation-empty-state-candidates__description"
      data-test="empty-state-description"
    >
      {{ $t(`${langPath}.description`) }}
    </p>

    <section class="recommendation-empty-state-candidates__actions">
      <ev-router-link
        :route="{ path: marketPlaceRoute }"
        data-test="empty-state-button"
      >
        {{ $t(`${langPath}.button`) }}
      </ev-router-link>

      <ev-router-link
        :route="{ name: 'PositionsEdit', params: { positionId: position.externalId } }"
        class="recommendation-empty-state-candidates__secondary-button"
        data-test="empty-state-edit"
        variant="tertiary"
        color="blue"
      >
        {{ $t(`${langPath}.editButton`) }}
      </ev-router-link>
    </section>
  </section>
</template>
<script>
import EvIcon from '@revelotech/everestV2/EvIcon'
import EvRouterLink from '@revelotech/everestV2/EvRouterLink'
import positionsRepository from '@/repository/companies-app/positions'

export default {
  name: 'RecommendationsEmptyStateCandidates',
  components: {
    EvRouterLink,
    EvIcon
  },
  props: {
    position: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      langPath: __langpath,
      marketPlaceRoute: '/companies/employers/candidates'
    }
  },
  async created () {
    const positions = await positionsRepository.getPositions([this.position.externalId])
    this.marketPlaceRoute = `/companies${positions[0].searchPageHref}`
  }
}
</script>
<style lang="scss">
.recommendation-empty-state-candidates {
  text-align: center;

  &__icon {
    margin-bottom: $base*6;
  }

  &__subtitle {
    @extend %subtitle1;

    margin-top: $base*6;
    padding: 0 $base*8;
  }

  &__description {
    @extend %body-text2;

    margin-top: $base*2;

    strong {
      color: $red-light;
      font-size: inherit;
    }
  }

  &__actions {
    display: inline-block;
    margin-bottom: $base * 6;
    margin-top: $base * 6;
  }

  &__secondary-button {
    margin-top: $base*6;
  }
}
</style>

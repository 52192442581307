<template>
  <section class="new-home-positions">
    <h1 class="new-home-positions__title">
      {{ $t(`${langPath}.title`) }}
    </h1>
    <ev-loader
      v-if="isFetchingPositionsSummary"
      data-test="positions-loader"
      title=""
      :message="$t(`${langPath}.loading`)"
    />
    <section
      v-else-if="hasPositions"
      class="new-home-positions__content"
    >
      <positions-listing-header data-test="positions-listing-header" />
      <position-card
        v-for="position in positionsSummary"
        :key="position.id"
        :position="position"
        :data-test-position-id="position.id"
      />
      <section class="new-home-positions__all-positions">
        <ev-button
          variant="tertiary"
          color="blue"
          data-test="all-positions-link"
          @click="redirectToPositions"
        >
          {{ $t(`${langPath}.allPositions`) }}
        </ev-button>
      </section>
    </section>
    <section
      v-else
      class="new-home-positions__empty-state"
    >
      <ev-icon
        name="file-check-alt"
        color="blue"
        size="medium"
        data-test="empty-state-icon"
      />
      <h1
        class="new-home-positions__empty-state-title"
        data-test="empty-state-title"
      >
        {{ $t(`${langPath}.emptyStateTitle`) }}
      </h1>
      <p
        class="new-home-positions__empty-state-subtitle"
        data-test="empty-state-subtitle"
      >
        {{ $t(`${langPath}.emptyStateSubtitle`) }}
      </p>
      <ev-button
        class="new-home-positions__empty-state-create-position"
        data-test="create-position-button"
        variant="primary"
        @click="redirectToPositionsCreate"
      >
        {{ $t(`${langPath}.createPosition`) }}
      </ev-button>
    </section>
  </section>
</template>

<script>
import EvButton from '@revelotech/everestV2/EvButton'
import EvLoader from '@revelotech/everestV2/EvLoader'
import EvIcon from '@revelotech/everestV2/EvIcon'
import PositionCard from './PositionCard'
import PositionsListingHeader from './PositionsListingHeader'
import { createNamespacedHelpers } from 'vuex'
import { POSITIONS } from '@/store/namespaces'

const positionsHelper = createNamespacedHelpers(POSITIONS)

export default {
  name: 'Positions',
  components: {
    EvButton,
    EvLoader,
    EvIcon,
    PositionCard,
    PositionsListingHeader
  },
  data () {
    return {
      langPath: __langpath
    }
  },
  computed: {
    ...positionsHelper.mapState([
      'positionsSummary',
      'isFetchingPositionsSummary'
    ]),
    hasPositions () {
      return this.positionsSummary.length > 0
    }
  },
  async created () {
    this.getPositionsSummary()
  },
  methods: {
    ...positionsHelper.mapActions(['getPositionsSummary']),
    redirectToPositions () {
      this.$router.push({ name: 'Positions' })
    },
    redirectToPositionsCreate () {
      this.$router.push({ path: '/positions/create' })
    }
  }
}
</script>

<style lang="scss">
.new-home-positions {
  background: $white;
  border: 1px solid $gray5;
  border-radius: var(--b-radius);
  box-shadow: $shadow-8;
  height: auto;
  margin-top: $base*6;
  padding: 6*$base;
  width: 100%;

  &__title {
    @extend %h6;

    @include margin(bottom, 2);
  }

  &__all-positions {
    @include margin(top, 2);

    align-items: flex-start;
    display: flex;
    flex-direction: column;
  }

  &__empty-state {
    align-items: center;
    display: flex;
    flex-direction: column;
    padding-top: $base*8;

    &-title {
      @extend %subtitle1;

      margin-top: $base*6;
    }

    &-subtitle {
      @extend %body-text2;

      margin-top: $base*2;
    }

    &-create-position {
      margin-bottom: $base*10;
      margin-top: $base*6;
    }
  }

  .ev-button {
    padding: $base*2;
  }
}
</style>

<template>
  <ul class="next-tasks-tabs">
    <li
      v-for="(tab, index) in tabs"
      :key="index"
      data-test="next-tasks-tab-item"
      @click="handleTabClick(tab.translationKey)"
    >
      <router-link
        class="next-tasks-tabs__item"
        :class="isActiveTab(tab.translationKey)"
        :data-ga-id="tabEventName(tab.translationKey)"
        :exact-path="true"
        :to="tab.to"
        :data-test="`next-tasks-tab-${tab.translationKey}`"
      >
        {{ $t(`${langpath}.${tab.translationKey}`) }}
        <ev-badge
          v-if="!isNaN(tab.count)"
          class="next-tasks-tabs__badge"
          :data-test="`tab-${tab.translationKey}-badge`"
          :title="tab.count.toString()"
          :variant="isActiveTabBadge(tab.translationKey)"
        />
      </router-link>
    </li>
  </ul>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import { APPLICANTS, NEXT_TASKS } from '@/store/namespaces'
import EvBadge from '@revelotech/everestV2/EvBadge'
import { toSnakeCase } from '@/helpers/case-style'

const applicantsHelper = createNamespacedHelpers(APPLICANTS)
const nextTasksHelper = createNamespacedHelpers(NEXT_TASKS)

export default {
  name: 'Tabs',
  components: { EvBadge },
  data () {
    return {
      langpath: __langpath
    }
  },
  computed: {
    ...applicantsHelper.mapState([
      'applicantsWithUnscheduledInterviewTotalEntries',
      'applicantsWithConductedInterviewTotalEntries',
      'applicantsWithSentProposalTotalEntries'
    ]),
    ...nextTasksHelper.mapState(['activeTab']),
    tabs () {
      return [
        {
          translationKey: 'unscheduledInterview',
          to: { name: 'NewHome' },
          count: this.applicantsWithUnscheduledInterviewTotalEntries
        },
        {
          translationKey: 'conductedInterview',
          to: { name: 'NewHome' },
          count: this.applicantsWithConductedInterviewTotalEntries
        },
        {
          translationKey: 'sentProposal',
          to: { name: 'NewHome' },
          count: this.applicantsWithSentProposalTotalEntries
        }
      ]
    }
  },
  async mounted () {
    await this.getApplicantsWithUnscheduledInterviews()
    await this.getApplicantsWithConductedInterviews()
    await this.getApplicantsWithSentProposals()
  },
  methods: {
    ...applicantsHelper.mapActions([
      'getApplicantsWithUnscheduledInterviews',
      'getApplicantsWithConductedInterviews',
      'getApplicantsWithSentProposals'
    ]),
    ...nextTasksHelper.mapActions(['setActiveTab']),
    isActiveTab (tabName) {
      return this.activeTab === tabName ? 'next-tasks-tabs__item--active' : ''
    },
    isActiveTabBadge (tabName) {
      return this.activeTab === tabName ? 'blue' : 'white'
    },
    handleTabClick (tabOption) {
      this.setActiveTab(tabOption)
    },
    tabEventName (translationKey) {
      return `clicked_on_${toSnakeCase(translationKey)}_tab`
    }
  }
}
</script>

<style lang="scss">
.next-tasks-tabs {
  border-bottom: 1px solid var(--bg-gray20);
  display: flex;
  font: var(--tx-body-small);
  font-weight: 600;
  list-style: none;
  margin-bottom: $base*4;
  padding-bottom: $base*4;

  &__badge {
    display: inline;
    margin-left: $base;
  }

  &__item {
    color: var(--tx-blue);
    margin-right: $base*6;
    padding-bottom: $base*4;
    position: relative;

    &:focus {
      outline: 0;
    }

    &--active {
      color: var(--tx-blue);

      &::after {
        background-color: var(--bg-blue);
        border-radius: 2px;
        bottom: 0;
        content: '';
        display: block;
        height: 3px;
        left: 0;
        position: absolute;
        transform: translateY(2px);
        width: 100%;
      }

      &:hover {
        color: var(--tx-blue);
      }
    }
  }
}
</style>

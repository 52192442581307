<template>
  <section>
    <div class="plan-info-card__plan-details-box">
      <span
        class="plan-info-card__plan-details-title"
        data-test="plan-details-valid-until"
      >
        {{ $t(`${langPath}.validUntil`) }}:
      </span>
      <span
        class="plan-info-card__plan-details-info"
        data-test="plan-details-end-date"
      >
        <strong>
          {{ $d(new Date(plan.endDate), 'dayMonthFullYear') }}
        </strong>
      </span>
    </div>
  </section>
</template>

<script>
export default {
  name: 'FreedomPlanInfo',
  props: {
    plan: {
      type: Object,
      default: undefined
    }
  },
  data () {
    return {
      langPath: __langpath
    }
  }
}
</script>

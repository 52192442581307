const getLastJob = (jobsList) => {
  const lastIndex = jobsList.length - 1
  const currentJobs = jobsList.filter(job => job.current)

  const sortedJobs =
    currentJobs.length
      ? sortByStartDate(jobsList)
      : sortByEndDate(jobsList)

  return sortedJobs[lastIndex]
}

const sortByEndDate = (jobsList) =>
  jobsList.sort((a, b) =>
    new Date(a.endDate).getTime() - new Date(b.endDate).getTime()
  )

const sortByStartDate = (jobsList) =>
  jobsList.sort((a, b) =>
    new Date(a.startDate).getTime() - new Date(b.startDate).getTime()
  )

export default getLastJob

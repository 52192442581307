<template>
  <div data-test="plan-info-card">
    <div v-if="availablePlan">
      <card-skeleton
        v-if="isLoadingCurrentPlan"
        data-test="plan-info-skeleton"
      />
      <section v-else class="plan-info-card">
        <h3>
          {{ $t(`${langPath}.${currentPlan.planName}`) }}
        </h3>
        <section
          class="plan-info-card__plan-details"
        >
          <div v-if="currentPlan.planId == availablePlanIds.prePaidPlanId">
            <pre-paid-plan-info
              :plan="currentPlan"
              data-test="plan-info-details-pre-paid"
            />
          </div>
          <div v-if="currentPlan.planId == availablePlanIds.freedomPlanId">
            <freedom-plan-info
              :plan="currentPlan"
              data-test="plan-info-details-freedom"
            />
          </div>
        </section>
      </section>
    </div>
  </div>
</template>

<script>
import CardSkeleton from './skeletons/CardSkeleton'
import PrePaidPlanInfo from './PlanInfo/PrePaidPlanInfo'
import FreedomPlanInfo from './PlanInfo/FreedomPlanInfo'
import { PLANS } from '@/store/namespaces'
import { createNamespacedHelpers } from 'vuex'
import companyPlansIds from '@/helpers/constants/company-plans'

const plansHelper = createNamespacedHelpers(PLANS)

export default {
  name: 'PlanInfoCard',
  components: {
    CardSkeleton,
    FreedomPlanInfo,
    PrePaidPlanInfo
  },

  data () {
    return {
      langPath: __langpath
    }
  },

  computed: {
    ...plansHelper.mapState([
      'currentPlan',
      'isLoadingCurrentPlan'
    ]),

    hasPlanInfo () {
      return Object.keys(this.currentPlan).length > 0
    },

    availablePlan () {
      if (!this.hasPlanInfo) {
        return false
      }
      return Object.values(this.availablePlanIds)
        .includes(this.currentPlan.planId)
    },

    availablePlanIds () {
      return companyPlansIds
    }
  },

  async created () {
    this.getCurrentPlan()
  },

  methods: {
    ...plansHelper.mapActions(['getCurrentPlan'])
  }
}
</script>

<style lang="scss">
.plan-info-card {
  background: $white;
  border: 1px solid $gray5;
  border-radius: var(--b-radius);
  box-shadow: $shadow-8;
  height: auto;
  margin-top: $base*6;
  padding: 6*$base;
  width: 100%;

  &__plan-details {
    margin-top: $base * 10;
  }

  &__plan-details-box {
    margin-bottom: $base * 5;
  }

  &__plan-details-info {
    font: var(--tx-title-3);
    margin-left: $base;
  }

  h3 {
    font: var(--tx-title-4);
  }
}
</style>

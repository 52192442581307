<template>
  <section class="recommendations-card" data-test="recommendations-card">
    <ev-loader
      v-if="isLoadingPositions"
      data-test="recommendations-card-loader"
      title=""
    />
    <recommendations-empty-state-positions v-else-if="!positions.length" />
    <section v-else>
      <h4 class="recommendations-card__title">
        {{ $t(`${langPath}.title`) }}
      </h4>
      <ev-select
        v-model="selectedPosition"
        class="recommendations-card__options"
        label=""
        name="select"
        :options="positionsSelectOptions"
        placeholder=""
        data-test="recommendations-positions-select"
        @input="handlePositionInput"
      />
      <ev-loader
        v-if="isLoadingCandidates"
        data-test="recommendations-card-loader"
        title=""
      />
      <section v-else>
        <p
          v-if="selectedPosition && recommendedCandidates.length"
          class="recommendations-card__new-candidates"
        >
          {{
            $tc(
              `${langPath}.recommendedNewCandidatesNumber`,
              recommendedCandidates.length,
              {
                number: recommendedCandidates.length
              }
            )
          }}
          <strong class="recommendations-card__new-candidates-percentage">
            100%
            {{
              $tc(
                `${langPath}.recommendedNewCandidatesAdherent`,
                recommendedCandidates.length
              )
            }}
          </strong>
          {{
            $t(
              `${langPath}.yourPosition`,
            )
          }}
        </p>

        <recommendations-empty-state-draft v-if="isDraft" :position="selectedPositionObject" />
        <recommendations-empty-state-candidates
          v-else-if="!recommendedCandidates.length"
          :position="selectedPositionObject"
        />
        <recommendations-card-candidates
          v-else
          :candidates="recommendedCandidates"
          :position="selectedPositionObject"
        />
      </section>
    </section>
  </section>
</template>

<script>
import EvSelect from '@revelotech/everestV2/EvSelect'
import EvLoader from '@revelotech/everestV2/EvLoader'
import { CANDIDATES, POSITIONS } from '@/store/namespaces'
import { createNamespacedHelpers } from 'vuex'
import RecommendationsCardCandidates from './RecommendationsCard/RecommendationsCardCandidates'
import RecommendationsEmptyStatePositions
  from './RecommendationsCard/RecommendationsEmptyStatePositions'
import RecommendationsEmptyStateCandidates
  from './RecommendationsCard/RecommendationsEmptyStateCandidates'
import RecommendationsEmptyStateDraft
  from './RecommendationsCard/RecommendationsEmptyStateDraft'

const candidatesHelper = createNamespacedHelpers(CANDIDATES)
const positionsHelper = createNamespacedHelpers(POSITIONS)

const formatRecommendedPositions = (positions) => {
  return positions.map(position => ({
    value: position.externalId,
    label: position.title
  }))
}

export default {
  name: 'RecommendationsCard',
  components: {
    EvSelect,
    EvLoader,
    RecommendationsCardCandidates,
    RecommendationsEmptyStatePositions,
    RecommendationsEmptyStateCandidates,
    RecommendationsEmptyStateDraft
  },
  data () {
    return {
      langPath: __langpath,
      selectedPosition: null,
      isLoadingCandidates: true,
      isLoadingPositions: true
    }
  },
  computed: {
    ...positionsHelper.mapState(['positions']),
    ...candidatesHelper.mapState(['recommendedCandidates']),
    positionsSelectOptions () {
      const result = []
      if (this.myPositions.length > 0) {
        result.push({
          label: this.$t(`${this.langPath}.RecommendationsSelectGroups.myPositions`),
          options: formatRecommendedPositions(this.myPositions)
        })
      }
      if (this.otherPositions.length > 0) {
        result.push({
          label: this.$t(`${this.langPath}.RecommendationsSelectGroups.otherPositions`),
          options: formatRecommendedPositions(this.otherPositions)
        })
      }
      return result
    },
    selectedPositionObject () {
      return this.positions.find(
        (position) => Number(position.externalId) === Number(this.selectedPosition)
      )
    },
    isDraft () {
      return this.selectedPositionObject?.draft || false
    },
    myPositions () {
      return this.positions.filter(position => (position.owner))
    },
    otherPositions () {
      return this.positions.filter(position => (!position.owner))
    }
  },
  async created () {
    this.isLoadingPositions = true
    await this.getShortPositions({ sortBy: 'updated_at' })

    if (this.positions.length) {
      this.isLoadingCandidates = true

      const lastPosition = Number(window.localStorage.getItem('recommendedSelectedPosition'))

      if (lastPosition) {
        this.selectedPosition = lastPosition
        await this.getRecommendedCandidates(this.selectedPosition)
      }

      for (let i = 0; i < this.myPositions.length && !this.recommendedCandidates.length; i++) {
        this.selectedPosition = this.myPositions[i].externalId
        await this.getRecommendedCandidates(this.selectedPosition)
      }

      for (let i = 0; i < this.otherPositions.length && !this.recommendedCandidates.length; i++) {
        this.selectedPosition = this.otherPositions[i].externalId
        await this.getRecommendedCandidates(this.selectedPosition)
      }

      this.isLoadingCandidates = false
    }

    window.localStorage.setItem(
      'recommendedSelectedPosition',
      this.selectedPosition
    )

    this.isLoadingPositions = false
  },
  methods: {
    ...positionsHelper.mapActions(['getShortPositions']),
    ...candidatesHelper.mapActions(['getRecommendedCandidates']),
    async handlePositionInput () {
      this.isLoadingCandidates = true
      window.localStorage.setItem(
        'recommendedSelectedPosition',
        this.selectedPosition
      )
      await this.getRecommendedCandidates(this.selectedPosition)
      this.isLoadingCandidates = false
    }
  }
}
</script>

<style lang="scss">
@import "@revelotech/everestV2/tx_overline.scss";

.recommendations-card {
  background: $white;
  border: 1px solid $gray5;
  border-radius: var(--b-radius);
  box-shadow: $shadow-8;
  height: auto;
  margin-top: $base*6;
  padding: $base*6;
  width: 100%;

  &__title {
    font: var(--tx-title-4);
    margin-bottom: $base*6;
  }

  &__loader {
    margin-bottom: $base*6;
  }

  &__new-candidates {
    @extend %tx-overline;

    margin-bottom: $base*6;
  }

  &__new-candidates-percentage {
    @extend %tx-overline;

    color: $red;
  }

  &__options {
    margin-bottom: $base*6;
  }

  &__empty-state {
    text-align: center;

    &-icon.recommendations-card__empty-state-icon {
      margin-bottom: $base*6;
    }

    &-subtitle {
      @extend %subtitle1;

      margin-top: $base*6;
      padding: 0 $base*8;
    }

    &-description {
      @extend %body-text2;

      margin-top: $base*2;

      strong {
        color: $red;
        font-size: inherit;
      }
    }

    &-actions {
      display: inline-block;
      margin-bottom: $base * 6;
      margin-top: $base * 6;
    }
  }
}
</style>
